import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import CodeFlowSnippet from '../../../snippets/oauth2-code-flow.mdx';
import LoginMethodsSnippet from '../../../snippets/login-methods-and-path-encoded.mdx';
import TestUsersSnippet from '../../../snippets/test-users.mdx';
import ProductionSnippet from '../../../snippets/set-up-production.mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Integrations",
  "sort": 0,
  "title": "ASP.NET Core 3.1",
  "subtitle": "Accept MitID, Swedish BankID, Norwegian BankID and other eID logins with ASP.NET Core 3.1 and Criipto Verify"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This tutorial demonstrates how to add user login to the existing ASP.NET Core 3.x application. If you are migrating .NET Core v2.x application to .NET Core v3.1, skip to the `}<a parentName="p" {...{
        "href": "#migrate-aspnet-core-v2x-sample-to-v3x"
      }}>{`Migrate .NET Core v2.x sample to .NET Core v3.1`}</a>{` section.`}</p>
    <p>{`Four steps are required to complete your first test login:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#register-your-application-in-criipto-verify"
        }}>{`Register your application in Criipto Verify`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#configure-the-oauth2-code-flow"
        }}>{`Configure your OAuth2 flow`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#configure-your-application-to-use-criipto-verify"
        }}>{`Configure your application to use Criipto Verify`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#trigger-login-and-logout-in-your-application"
        }}>{`Trigger authentication in your application`}</a></li>
    </ol>
    <p>{`This explains how to set up your application and test with test users. To use real eIDs for login the setup is the same, but you must be `}<a parentName="p" {...{
        "href": "#setting-up-for-production"
      }}>{`set up for Production`}</a></p>
    <p>{`And note that you need test eID users to see your code in action. How to get those is `}<a parentName="p" {...{
        "href": "#test-users"
      }}>{`described further down`}</a>{`.`}</p>
    <p>{`You may get a completed and ready to run `}<a parentName="p" {...{
        "href": "https://github.com/criipto/dotnet-core-v3.x-sample"
      }}>{`sample from GitHub`}</a>{` showing the below recipe in the simplest of ASP.NET Core MVC applications.`}</p>
    <p>{`To modify your existing application to work with Criipto Verify follow the steps below.`}</p>
    <h2>{`Register Your Application in Criipto Verify`}</h2>
    <p>{`After you signed up for Criipto Verify, you must register an application before you can actually try logging in with any eID.`}</p>
    <p>{`Once you register your application you will also need some of the information for communicating with Criipto Verify. You get these details from the settings of the application in the dashboard.`}</p>
    <p>{`Specifically you need the following information to configure you application`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Client ID`}</em>{` to identify you application to Criipto Verify. In the case below we chose `}<inlineCode parentName="li">{`urn:criipto:samples:no1`}</inlineCode></li>
      <li parentName="ul"><em parentName="li">{`Domain`}</em>{` on which you will be communicating with Criipto Verify. Could be for example `}<inlineCode parentName="li">{`samples.criipto.id`}</inlineCode></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70.3125%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACEUlEQVQ4y5WRS2/TQBSF56dQYAVLIKzKDtGwyQ6pq5ZWLAqpsqKhsKI/r22akpfzcOLEY3vGTvx2XrA4aCZ2KqEmFYtPZzT3dWYueb5bwIu3H5Db28ebwgHy+yd4tlvAzqs9PH39Ho9zeTx6+Q47KU9y+a2Qo+I5Pn75BqGfSj9wfPpdng9Pyjg4OcNx8Ryl8gVKZxc4/foTh5/L6/wjoRnFFaRd/YX6VQWNqxu0KlXULq+hpHe1ywp6tTrmjo2ZzRFZJprXVSg3t+jc1u6FJIwhYRamnElEoTynGjMLsXUXzxB1sWWmrOIJZyDiYjwcyqZZ82yASHQ0Dc5gIN1lRdmgLOaORutBRDgST1pxd147fQCR51OKsaZJY8TXdXBVha324fQH4D0VrKfKhNg0ERnGRmLTQKDrcPp9RAaVd8TuqdBqdbBOF6zdgd5ogTZb4N0uwtEIwXC4ERF3BwPQlgJ3oMHVNJCIc0STCWa+j3kQ4E+SSH7HMZZRtJ04RmAYsDpdUKUNR+2DRIzB5zY8xiUzz8MiCDD3fambEPFlGCKgFMNGE1q9Ab2pgARik4YJ12LwuC2dLsJwVSj0PtKYeJGn6zDbHenQUNorh4Ftw2ccU9fDMp3+EItUQ7EcShELFUsJGcOEcYxNSz59vs3ZP4h/jExTLifUdQlJbBvxZIJoPMbUdf+roUA21HVElMqGfwGGi8+gq2SkkQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Register App",
          "title": "Register App",
          "src": "/static/9b0c07c946d1ade2ab1a607b8443dead/e5715/register-app.png",
          "srcSet": ["/static/9b0c07c946d1ade2ab1a607b8443dead/8514f/register-app.png 192w", "/static/9b0c07c946d1ade2ab1a607b8443dead/804b2/register-app.png 384w", "/static/9b0c07c946d1ade2ab1a607b8443dead/e5715/register-app.png 768w", "/static/9b0c07c946d1ade2ab1a607b8443dead/5205c/register-app.png 833w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3>{`Register callback URLs`}</h3>
    <p>{`Before you can start sending authentication requests to Criipto Verify you need to register the URLs on which you want to receive the returned `}<em parentName="p">{`JSON Web Token`}</em>{`, JWT.`}</p>
    <p>{`The Callback URL of your application is the URL where Criipto Verify will redirect to after the user has authenticated in order for the OpenID Connect ASP.NET middleware to complete the authentication process.`}</p>
    <p>{`You will need to add these URLs to the list of allowed URLs for your application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`https://localhost:5001/callback
http://localhost:5000/callback
https://localhost:5001/signout
http://localhost:5000/signout
`}</code></pre>
    <p>{`If you deploy your application to a different URL you will also need to ensure to add that URL to the Callback URLs. `}</p>
    <h2>{`Configure the OAuth2 code flow`}</h2>

    <CodeFlowSnippet mdxType="CodeFlowSnippet" />
    <h2>{`Install dependencies`}</h2>
    <p>{`To integrate Criipto Verify with ASP.NET Core you will use the Cookie and OpenID Connect (OIDC) authentication handlers. The seed project already references the ASP.NET Core meta package (Microsoft.AspNetCore.App) which includes all NuGet packages shipped by Microsoft as part of ASP.NET Core 3.1, including the packages for the Cookie and OIDC authentication handlers.`}</p>
    <p>{`If you are adding this to your own existing project, and you have not referenced the meta package, then please make sure that you add the Microsoft.AspNetCore.Authentication.Cookies and Microsoft.AspNetCore.Authentication.OpenIdConnect packages to your application.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-console"
      }}>{`dotnet add package Microsoft.AspNetCore.Authentication.Cookies
dotnet add package Microsoft.AspNetCore.Authentication.OpenIdConnect
`}</code></pre>
    <h3>{`Configure OpenID Connect Middleware`}</h3>
    <p>{`To enable authentication in your ASP.NET Core application, use the OpenID Connect (OIDC) middleware.
Go to the `}<inlineCode parentName="p">{`ConfigureServices`}</inlineCode>{` method of your `}<inlineCode parentName="p">{`Startup`}</inlineCode>{` class. To add the authentication services, call the `}<inlineCode parentName="p">{`AddAuthentication`}</inlineCode>{` method. To enable cookie authentication, call the `}<inlineCode parentName="p">{`AddCookie`}</inlineCode>{` method.`}</p>
    <p>{`Next, configure the OIDC authentication handler. Add a call to `}<inlineCode parentName="p">{`AddOpenIdConnect`}</inlineCode>{`. Configure the necessary parameters, such as `}<inlineCode parentName="p">{`ClientId`}</inlineCode>{`, `}<inlineCode parentName="p">{`ClientSecret`}</inlineCode>{`, `}<inlineCode parentName="p">{`ResponseType`}</inlineCode>{`, and not least the `}<inlineCode parentName="p">{`Authority`}</inlineCode>{`. The latter is used by the middleware to get the metadata describing the relevant endpoints, the signing keys etc.`}</p>
    <p>{`The OIDC middleware requests both the `}<inlineCode parentName="p">{`openid`}</inlineCode>{` and `}<inlineCode parentName="p">{`profile`}</inlineCode>{` scopes by default, but note that Criipto Verify by nature returns only the information derived from the underlying eID service. Changing the scopes does not affect the amount and nature of information delivered from the user information endpoint.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`// Startup.cs

public void ConfigureServices(IServiceCollection services)
{
    services.Configure<CookiePolicyOptions>(options =>
    {
        // This lambda determines whether user consent for non-essential cookies is needed for a given request.
        options.CheckConsentNeeded = context => true;
        options.MinimumSameSitePolicy = SameSiteMode.None;
    });

    services.AddAuthentication(options => {
        options.DefaultScheme = CookieAuthenticationDefaults.AuthenticationScheme;
        options.DefaultChallengeScheme = OpenIdConnectDefaults.AuthenticationScheme;
    })
    .AddCookie()
    .AddOpenIdConnect(options => {
        options.ClientId = Configuration["Criipto:ClientId"]; // ClientID from application registration
        options.ClientSecret = Configuration["Criipto:ClientSecret"]; // Client secret from oauth2 code flow
        options.Authority = $"https://{Configuration["Criipto:Domain"]}/"; // Domain from application registration
        options.ResponseType = "code";

        // The next to settings must match the Callback URLs in Criipto Verify
        options.CallbackPath = new PathString("/callback"); 
        options.SignedOutCallbackPath = new PathString("/signout");

        // Hook up an event handler to set the acr_value of the authorize request
        // In a real world implementation this is probably a bit more flexible
        options.Events = new OpenIdConnectEvents() {
            OnRedirectToIdentityProvider = context => {
                context.ProtocolMessage.AcrValues = context.Request.Query["loginmethod"];
                return Task.FromResult(0);
            }
        };
    });

    services.AddMvc();
}
`}</code></pre>
    <p><em parentName="p">{`Note`}</em>{` that the above code dynamically sets the `}<inlineCode parentName="p">{`AcrValues`}</inlineCode>{` by picking it from the query string. In the general case, this may, of course, be set in other ways. Just note that it is dynamically set at the time of the actual login.`}</p>
    <h3>{`Choosing the specific login method`}</h3>

    <LoginMethodsSnippet mdxType="LoginMethodsSnippet" />
    <h3>{`Enable the OpenID Connect middleware`}</h3>
    <p>{`Next, add the authentication middleware. In the `}<inlineCode parentName="p">{`Configure`}</inlineCode>{` method of the `}<inlineCode parentName="p">{`Startup`}</inlineCode>{` class, call the `}<inlineCode parentName="p">{`UseAuthentication`}</inlineCode>{` and `}<inlineCode parentName="p">{`UseAuthorization`}</inlineCode>{` method. Make sure to call `}<inlineCode parentName="p">{`UseAuthentication`}</inlineCode>{` method before `}<inlineCode parentName="p">{`UseAuthorization`}</inlineCode>{` method, but both after `}<inlineCode parentName="p">{`UseRouting`}</inlineCode>{` method.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`// Startup.cs

public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
    if (env.IsDevelopment())
    {
        app.UseDeveloperExceptionPage();
    }
    else
    {
        app.UseExceptionHandler("/Home/Error");
        // The default HSTS value is 30 days. You may want to change this for production scenarios, see https://aka.ms/aspnetcore-hsts.
        app.UseHsts();
    }

    app.UseHttpsRedirection();
    app.UseStaticFiles();
    app.UseCookiePolicy();

    app.UseRouting();

    app.UseAuthentication();
    app.UseAuthorization();
    

    app.UseEndpoints(routes =>
    {
        routes.MapControllerRoute("default", "{controller=Home}/{action=Index}/{id?}");
    });
}
`}</code></pre>
    <h2>{`Trigger Login and Logout in Your Application`}</h2>
    <p>{`After the middleware for performing the authentication is wired up, the next step is to perform the actual authentication.`}</p>
    <h3>{`Protected resources trigger login`}</h3>
    <p>{`One way to trigger the authentication flow is to tag routes in ASP.NET MVC with the `}<inlineCode parentName="p">{`Authorize`}</inlineCode>{`. This is a way of telling the framework to only allow requests from authenticated users.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`[Authorize] // If not already authenticated, this kicks off the process
public IActionResult Protected()
{
    return View();
}
`}</code></pre>
    <p>{`Note that you may plug in your own Authorization handlers derived from `}<inlineCode parentName="p">{`Microsoft.AspNetCore.Authorization.AuthorizationHandler<TRequirement>`}</inlineCode>{` to add additional guards beyond just authentication.`}</p>
    <h3>{`Explicit logout`}</h3>
    <p>{`Logout requires both terminating the local session by removing the cookies as well as telling Criipto Verify that the session is over.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`public async Task Logout()
{
    // Call the server to terminate the session
    await HttpContext.SignOutAsync(OpenIdConnectDefaults.AuthenticationScheme);

    // Remove authnetication cookies
    await HttpContext.SignOutAsync(CookieAuthenticationDefaults.AuthenticationScheme);
}
`}</code></pre>
    <h3>{`Test users`}</h3>

    <TestUsersSnippet mdxType="TestUsersSnippet" />
    <h2>{`The runtime flow`}</h2>
    <p>{`In summary, the steps above will lead to a runtime flow looks like this:`}</p>
    <ol>
      <li parentName="ol">{`The web server starts the application which configures and initializes the OpenID Connect middleware. The middleware is configured with a URL from which it retrieves the metadata describing the various endpoints and encryption keys, such as the token and userinfo endpoints as well the token signing certificates`}</li>
      <li parentName="ol">{`The user picks the login method, or the application is hardcoded to one of the `}<a parentName="li" {...{
          "href": "#choosing-the-specific-login-method"
        }}>{`authentication options`}</a></li>
      <li parentName="ol">{`A request for a resource protected by the `}<inlineCode parentName="li">{`[Authorization]`}</inlineCode>{` kicks off the OIDC middleware login flow`}</li>
      <li parentName="ol">{`The user's browser is redirected to the Criipto Verify service where actual login happens`}</li>
      <li parentName="ol">{`A callback with an issued `}<em parentName="li">{`authorization code`}</em>{` is sent back to the application and intercepted by the OIDC middleware`}</li>
      <li parentName="ol">{`The middleware calls the Criipto Verify service to exchange the code for an `}<em parentName="li">{`access token`}</em>{`. Note that this is a direct server to server call which - unlike the other communication - does not pass through the browser`}</li>
      <li parentName="ol">{`The access token is used by the OIDC middleware to retrieve the available user information which is set as claims on the user principal.`}</li>
    </ol>
    <p>{`If you want to inspect what is actually going on you may see much of it if you use for example chrome and turn on the developer tools to inspect the network traffic.`}</p>
    <h2>{`Setting up for Production`}</h2>

    <ProductionSnippet mdxType="ProductionSnippet" />
    <h2>{`Migrate ASP.NET Core v2.x sample to v3.x`}</h2>
    <p>{`This section demonstrates how to migrate ASP.NET Core v2.x `}<a parentName="p" {...{
        "href": "https://github.com/criipto/aspnetcore-oidc-sample"
      }}>{`sample from GitHub`}</a>{` to ASP.NET Core v3.1`}</p>
    <p>{`Before proceeding, make sure you have the appropriate ASP.NET Core 3.1 SDK.`}</p>
    <p>{`Following steps are required to complete your first test login:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#modify-aspnetcore-oidc-project-file"
        }}>{`Modify aspnetcore-oidc project file`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#restore-dependencies"
        }}>{`Restore dependencies`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#modify-startupcs-file"
        }}>{`Modify Startup.cs file`}</a></li>
    </ol>
    <h3>{`Modify aspnetcore-oidc project file`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Change the `}<inlineCode parentName="p">{`TargetFramework`}</inlineCode>{` from `}<inlineCode parentName="p">{`netcoreapp2.2`}</inlineCode>{` to `}<inlineCode parentName="p">{`netcoreapp3.1`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Remove the following line from the `}<inlineCode parentName="p">{`PropertyGroup`}</inlineCode></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-xml"
          }}>{`  <AspNetCoreHostingModel>InProcess</AspNetCoreHostingModel>
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Remove following `}<inlineCode parentName="p">{`PackageReference`}</inlineCode>{` entries from `}<inlineCode parentName="p">{`ItemGroup`}</inlineCode></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-xml"
          }}>{`  <PackageReference Include="Microsoft.AspNetCore.App" />
  <PackageReference Include="Microsoft.AspNetCore.Razor.Design" Version="2.2.0" PrivateAssets="All" />
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add following `}<inlineCode parentName="p">{`PackageReference`}</inlineCode>{` entries to the `}<inlineCode parentName="p">{`ItemGroup`}</inlineCode></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-xml"
          }}>{`  <PackageReference Include="Microsoft.AspNetCore.Authentication.Cookies" Version="2.2.0" />
  <PackageReference Include="Microsoft.AspNetCore.Authentication.OpenIdConnect" Version="3.1.1" />
`}</code></pre>
      </li>
    </ol>
    <h3>{`Restore dependencies`}</h3>
    <p>{`Restore dependencies to make sure they are compatible with a new version of .NET Core.`}</p>
    <p>{`Run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-console"
      }}>{`  dotnet restore
`}</code></pre>
    <h3>{`Modify Startup.cs file`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`In the `}<inlineCode parentName="p">{`ConfigureServices`}</inlineCode>{` method, remove the `}<inlineCode parentName="p">{`SetCompatibilityVersion`}</inlineCode>{` method. Change the line `}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-cs"
          }}>{`  services.AddMvc().SetCompatibilityVersion(CompatibilityVersion.Version_2_2);
`}</code></pre>
        <p parentName="li">{`into `}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-cs"
          }}>{`  services.AddMvc();
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the `}<inlineCode parentName="p">{`Configure`}</inlineCode>{` method, change the second argument's type from `}<inlineCode parentName="p">{`IHostingEnvironment`}</inlineCode>{` to `}<inlineCode parentName="p">{`IWebHostEnvironment`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add the following `}<inlineCode parentName="p">{`using`}</inlineCode></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-cs"
          }}>{`  using Microsoft.Extensions.Hosting;
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add `}<inlineCode parentName="p">{`app.UseRouting();`}</inlineCode>{` before `}<inlineCode parentName="p">{`app.UseAuthentication();`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add `}<inlineCode parentName="p">{`app.UseAuthorization();`}</inlineCode>{` after `}<inlineCode parentName="p">{`app.UseAuthentication();`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Replace the following code from the `}<inlineCode parentName="p">{`Configure`}</inlineCode>{` method`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-cs"
          }}>{`  app.UseMvc(routes =>
  {
      routes.MapRoute(
          name: "default",
          template: "{controller=Home}/{action=Index}/{id?}");
  });
`}</code></pre>
        <p parentName="li">{`with`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-cs"
          }}>{`  app.UseEndpoints(routes =>
  {
      routes.MapControllerRoute("default", "{controller=Home}/{action=Index}/{id?}");
  });
`}</code></pre>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      